.header-bread-crumb {
  font-size: 10px;
  line-height: 14px;
  color: var(--gray);
  margin-bottom: 15px;

  span:last-child {
    color: var(--scorpion);
  }
}

.header-tab-bar {
  font-size: 14px;
  line-height: 22px;
  font-weight: normal;

  &.ant-tabs-top {
    .ant-tabs-nav::before {
      border-bottom: 1px solid var(--alto);
    }
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: var(--deep-cerulean);
      font-weight: 600;
    }
  }

  .ant-tabs-ink-bar {
    background: var(--deep-cerulean);
  }
}
